import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ChatBubble, ChatMeta } from '../Chat';
import { Box } from '../Box';
import { Container } from '../Container';
import { Props, Playground } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "chat"
    }}>{`Chat`}</h1>
    <p>{`ChatBubble is a `}<a parentName="p" {...{
        "href": "/components/Box"
      }}>{`Box`}</a>{` with a bit of extra styling, it can be used for a list of items, or to highlight an important piece of UI.`}</p>
    <p>{`Together with ChatMeta it forms the content of a chat conversation.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={ChatBubble} mdxType="Props" />
    <Props of={ChatMeta} mdxType="Props" />
    <p>{`Props table might not render due to a `}<a parentName="p" {...{
        "href": "https://github.com/pedronauck/docz/issues/777"
      }}>{`bug in docz`}</a></p>
    <h3 {...{
      "id": "main-props-for-chatbubble"
    }}>{`Main props for ChatBubble`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`variant`}</inlineCode>{`: `}<inlineCode parentName="li">{`me`}</inlineCode>{` or `}<inlineCode parentName="li">{`them`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`avatarURL`}</inlineCode>{`: url to avatar image. Currently only used for `}<inlineCode parentName="li">{`variant="them"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`children`}</inlineCode>{`: string or react elements to fill the chat bubble with`}</li>
    </ul>
    <h3 {...{
      "id": "main-props-for-chatmeta"
    }}>{`Main props for ChatMeta`}</h3>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`children`}</inlineCode>{`: string`}</li>
    </ul>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <p>{`Wrap it in a `}<inlineCode parentName="p">{`Container`}</inlineCode>{` and you're good to go.`}</p>
    <Playground __position={1} __code={'<Container width=\"narrow\" bg=\"neutral.lightest\">\n  <ChatMeta>\n    This is the beginning of your conversation with your host. <br />\n    Why don\'t you say hi?!\n  </ChatMeta>\n  <ChatBubble variant=\"me\">Hi there!</ChatBubble>\n  <ChatBubble variant=\"me\">Super excited to meet you.</ChatBubble>\n  <ChatMeta>Yesterday</ChatMeta>\n  <ChatBubble\n    variant=\"them\"\n    avatarURL=\"https://randomuser.me/api/portraits/women/76.jpg\"\n  >\n    Me, too! What are you most interested about?\n  </ChatBubble>\n  <ChatBubble\n    variant=\"them\"\n    avatarURL=\"https://randomuser.me/api/portraits/women/76.jpg\"\n  >\n    I\'d love to make sure you get the most out of the day.\n  </ChatBubble>\n  <ChatMeta>5 minutes ago</ChatMeta>\n  <ChatBubble variant=\"me\">\n    Can\'t wait to see how you work, but tbh, I\'m most excited to see your\n    favourite spots in the city.\n  </ChatBubble>\n  <ChatBubble\n    variant=\"them\"\n    avatarURL=\"https://randomuser.me/api/portraits/women/76.jpg\"\n  >\n    Oh, there are so many.\n  </ChatBubble>\n  <ChatBubble\n    variant=\"them\"\n    avatarURL=\"https://randomuser.me/api/portraits/women/76.jpg\"\n    isTyping={true}\n  ></ChatBubble>\n</Container>'} __scope={{
      props,
      DefaultLayout,
      ChatBubble,
      ChatMeta,
      Box,
      Container,
      Props,
      Playground,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Container width="narrow" bg="neutral.lightest" mdxType="Container">
    <ChatMeta mdxType="ChatMeta">
      This is the beginning of your conversation with your host. <br />
      Why don't you say hi?!
    </ChatMeta>
    <ChatBubble variant="me" mdxType="ChatBubble">Hi there!</ChatBubble>
    <ChatBubble variant="me" mdxType="ChatBubble">Super excited to meet you.</ChatBubble>
    <ChatMeta mdxType="ChatMeta">Yesterday</ChatMeta>
    <ChatBubble variant="them" avatarURL="https://randomuser.me/api/portraits/women/76.jpg" mdxType="ChatBubble">
      Me, too! What are you most interested about?
    </ChatBubble>
    <ChatBubble variant="them" avatarURL="https://randomuser.me/api/portraits/women/76.jpg" mdxType="ChatBubble">
      I'd love to make sure you get the most out of the day.
    </ChatBubble>
    <ChatMeta mdxType="ChatMeta">5 minutes ago</ChatMeta>
    <ChatBubble variant="me" mdxType="ChatBubble">
      Can't wait to see how you work, but tbh, I'm most excited to see your
      favourite spots in the city.
    </ChatBubble>
    <ChatBubble variant="them" avatarURL="https://randomuser.me/api/portraits/women/76.jpg" mdxType="ChatBubble">
      Oh, there are so many.
    </ChatBubble>
    <ChatBubble variant="them" avatarURL="https://randomuser.me/api/portraits/women/76.jpg" isTyping={true} mdxType="ChatBubble"></ChatBubble>
  </Container>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      